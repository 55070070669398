@import '../styles/bootstrap-imports.scss';

$process-input-width: 350px;

@mixin round-icon-bg {
  width: 25px;
  height: 25px;
  border-radius: 50%;
}

.w-80 {
  width: 80%;
}

.header-section {
  height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  @include media-breakpoint-down(md) {
    height: unset;
  }

  &-padding {
    height: 100px;
  }
}

@media (max-width: 600px) {
  .header-section-padding {
    height: 150px;
    &-withDiscount {
      padding-bottom: 20px;
    }
  }
}
