@import './colors.scss';

$datepicker__background-color: transparent !default;
$datepicker__item-size: 40px !default;
$datepicker__day-margin: 3px;
$datepicker__font-size: 15px !default;
$datepicker__font-family: 'Poppins', sans-serif;
$datepicker__muted-color: #b2b2b2 !default;
$datepicker__selected-color: #144a3d !default;
$datepicker__border-radius: 8px !default;
$datepicker__border-color: transparent !default;
$datepicker__text-color: $text-secondary !default;

@import 'react-datepicker/src/stylesheets/datepicker.scss';

.react-datepicker {
  display: flex;
  justify-content: center;

  &__current-month {
    color: $text-secondary;
    text-transform: capitalize;
    font-size: 16px;
    font-weight: 700;
  }

  &__day-name {
    font-weight: 700;
    font-size: 16px;
    color: $text-secondary;
  }
  &__day {
    font-weight: 600;

    background-color: #f3f3f3;
    border-radius: $datepicker__border-radius;

    &--selected,
    &--in-selecting-range,
    &--in-range {
      background-color: $datepicker__selected-color;
    }

    &--selected {
      color: #ffffff;
    }

    &--disabled {
      background-color: transparent;
    }

    $yada: &;

    &--disabled#{$yada}--selected {
      background-color: $datepicker__selected-color;
    }

    &--outside-month {
      visibility: hidden;
    }
  }

  @mixin arrow() {
    height: 60px;
    width: 60px;
    background-size: 55% 55% !important;
    border-radius: 50%;
    background-repeat: no-repeat;
    background-position: center;
  }

  $navigation-offset-horizontal: -200px;
  $navigation-offset-top: 140px;

  &__navigation {
    top: $navigation-offset-top;

    &:hover {
      box-shadow: 0px 0px 8px 0px rgb(0 0 0 / 20%);
    }

    &--previous {
      background: url('/svgs/prev.svg');
      @include arrow();
      left: $navigation-offset-horizontal;
    }

    &--next {
      background: url('/svgs/next.svg');
      @include arrow();
      right: $navigation-offset-horizontal;
    }

    &-icon {
      &::before {
        display: none;
      }
    }
  }
}
