@import 'react-credit-cards/lib/styles.scss';
@import 'react-big-calendar/lib/sass/styles';
@import 'react-datepicker/dist/react-datepicker';

@import './admin';

@import './colors.scss';
@import './bootstrap-variables';
@import './bootstrap-imports';
@import './nav-underscored';
@import './buttons';
@import './bg';
@import './checkbox';
@import './calendar';
@import './typography';
@import './spacing';
@import './sizing';

.hero_section {
  @media (max-width: 660px) {
    margin-top: 150px !important;
  }
}

.source-button {
  display: none;
}

.bs-docs-section {
  margin-top: 4em;
}

.d-md-inline-block{
  @media screen and (max-width: 991px) {
    display: inline-block!important;
  }
}

.mobile-fs-14{
  @media screen and (max-width: 567px) {
    font-size: 14px;
  }
}

.mobile-extrasmall-fs-12{
  @media screen and (max-width: 380px) {
    font-size: 12px;
  }
}

.card-mobile{
  display: none;
  @media screen and (max-width: 768px) {
    display: flex !important;
  }
}

.bs-components {
  position: relative;
}

.form-control {
  border: 2px solid #b2b2b2 !important;
  border-radius: 8px;

  &:focus {
    box-shadow: none;
    border-color: #b2b2b2 !important;
  }
}

.mt-md-sm-custom{
  @media (max-width: 991px) {
    margin-top: 70px!important;
  }
  @media (max-width: 440px) {
    margin-top: 0!important;
  }
}

html,
body,
#__next {
  height: 100%;
  font-family: 'Poppins', sans-serif;
  // scroll-behavior: smooth;
  font-weight: 500;
}

body.body-with-overlay-open {
  overflow: hidden;
}

.mw {
  &-100 {
    max-width: 100%;
  }
  &-75 {
    max-width: 75%;
  }
  &-50 {
    max-width: 50%;
  }
  &-25 {
    max-width: 25%;
  }
}

.mh {
  &-50 {
    max-height: 50%;
  }
}

.payments {
  * {
    flex-grow: 1;
  }
}

.rotated {
  &-180 {
    transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
  }
}

@media screen and (max-width: 991px) {
  .mt-mb-mobile-80{
    margin-top: 80px;
    margin-bottom: 80px;
  }
  .mt-mobile-0{
    margin-top: 0 !important;
  }
}

@media screen and (max-width: 768px) {
  .mt-red-box-mobile{
    margin-top: 90px!important
  }
}

.border {
  &-bold {
    border-width: 3px;
    border-style: solid;
  }

  &-rounded {
    &-small {
      border-radius: 10px;
    }

    &-big {
      border-radius: 30px;
    }
  }
}

.line-height {
  &-normal {
    line-height: 1em;
  }

  &-big {
    line-height: 1.8em;
  }
}

.col-no-p {
  padding-left: 0;
  padding-right: 0;
}

@include media-breakpoint-up(md) {
  .push-md-1 {
    margin-right: 8.3333333333%;
  }
}

.overflow-hidden {
  overflow: hidden;
}

.curvy-top {
  &-white {
    background: url('/images/curvy-top-white.png');
    background-size: cover;
    background-position: center;
    height: 35px;
  }
}

@include media-breakpoint-down(md) {
  .curvy-top-white {
    height: 100px;
  }
}

.page-holder {
  min-height: 92.7vh;
}

.text-decoration-line-through {
  text-decoration: line-through;
}

@media screen and (min-width: 1337px) {
  .toasts {
    position: fixed;
    top: 80px;
    right: 40px;
  }
}
@media screen and (max-width: 1336px) {
  .toasts {
    position: fixed;
    bottom: 50px;
    left: calc(50% - 150px);
  }
}

.toasts {
  z-index: 99999;
}

.toast {
  width: 300px;
  z-index: 9999;
}

.page-padding {
  padding-bottom: 10%;
  @include media-breakpoint-down(sm) {
    .sm-mb-40{
      margin-bottom: 80px;
    }
  }
}

.mh-40{
  max-height: 40px;
}

.cursor-pointer {
  cursor: pointer;
}

.flex-unset{
  flex: unset;
}

.modal-footer {
  justify-content: space-between;
}

.sticky-container-right {
  width: 40vw;

  @include media-breakpoint-down(md) {
    width: 90% !important;
    padding: 0 5px;
  }

  &.wide {
    width: 80% !important;
    @include media-breakpoint-down(md) {
      width: 93% !important;
      padding: 0 5px;
    }
  }

  &.wider {
    width: 100% !important;
    padding: 0;

    @include media-breakpoint-up(xl) {
      width: 80vw;
    }

    @include media-breakpoint-down(md) {
      width: 93% !important;
      padding: 0 5px;
    }
  }

  @media (min-width: 2000px) {
    width: 28vw;
  }

  &.flavor-overlay {
    width: 100%;
  }
}

.sticky-container-left {
  max-width: 550px;
}

.form-group label {
  font-size: 14px;
  margin-bottom: 0.15rem;
}

.border-top-separator {
  border-top: solid 1px #e5e5e5;
}

.dog-edition-separator {
  @extend .border-top-separator;
  margin-left: -30px;
  margin-right: -30px;
  width: calc(60px + 100%);
}

.sticky-left-col {
  position: sticky;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 420;
  top: 73px;
  min-height: 100vh;

  @include media-breakpoint-down(md) {
    min-height: 200px;
  }

  &-recipe {
    min-height: 10px;
    height: 340px;
  }

  &-smaller {
    height: 320px;
  }
}

@media (min-width: 370px) {
  .sticky-left-col-recipe {
    height: 410px;
  }
}
@media (min-width: 420px) {
  .sticky-left-col-recipe {
    height: 470px;
  }
}

@include media-breakpoint-up(md) {
  .sticky-left-col-recipe {
    height: 655px;
  }
}
@include media-breakpoint-up(lg) {
  .sticky-left-col-recipe {
    // height: 85vh;
  }
}

@include media-breakpoint-down(lg) {
  .sticky-container-right {
    width: 100%;
    padding-left: 0;
    padding-right: 0;
  }
}

@include media-breakpoint-up(lg) {
  .sticky-left-col-smaller {
    height: 81vh;
  }
}

.top-navbar {
  position: fixed;
  width: 100%;
  background-color: #fff;
  z-index: 99999;
}

.close-btn-in-modal {
  position: absolute;
  top: 0;
  right: 5px;
  font-size: 35px;
}

.minimal-page {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 75vh;
}

.rbc-toolbar-label {
  text-align: right !important;
}

.fixed-table {
  table-layout: fixed;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 10%) !important;

  &:hover {
    box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 25%) !important;
  }
}

@mixin rotation($rotation) {
  transform: rotate($rotation);
  -ms-transform: rotate($rotation);
  -moz-transform: rotate($rotation);
  -webkit-transform: rotate($rotation);
}

@for $i from 3 to 7 {
  .rotation-#{$i} {
    @include rotation(#{$i}deg);
  }
}
@for $i from 3 to 7 {
  .rotation-n#{$i} {
    @include rotation(-#{$i}deg);
  }
}

@include media-breakpoint-down(md) {
  img[src='/svgs/logo.svg'] {
    width: 130px;
  }
}

@include media-breakpoint-down(md) {
  .navbar-brand {
    width: 110px;
  }
}

.collapse.show.navbar-collapse {
  height: 100vh;
}

.navbar-collapse.collapsing {
  transition: none !important;
  height: 100vh;
}

.p-mobile-page {
  padding-left: 25px;
  padding-right: 25px;
}

.horizontally-scrollable {
  overflow-x: auto;
  &::-webkit-scrollbar {
    display: none;
  }
}

.form-control {
  font-weight: 700;
}

@include media-breakpoint-down(md) {
  .mobile-text-left {
    text-align: left !important;
  }

  .mobile-text-right {
    text-align: right !important;
  }

  .mobile-text-center {
    text-align: center !important;
  }
}

.info-button {
  position: relative;
  max-width: 24px;
}

.text-shadow {
  text-shadow: 0 0 12px black;
}

.border-bottom-recipe {
  border-bottom: solid 2px $purple2;
}

.ctaText {
  font-size: 18px;
  font-weight: 700;
}

.site-subtitle {
  font-size: 18px;
  line-height: 26px;
  font-weight: 500;
  color: $text-secondary;
}

button:focus {
  outline: none !important;
  box-shadow: none !important;
}

.no-hover-green:hover {
  background-color: #126e58 !important;
  border-color: #126e58 !important;
}

.no-hover-red:hover {
  background-color: #ff4844 !important;
  border-color: #ff4844 !important;
}

.btn-fix-issues-list,
.btn-fix-issues-list:active,
.btn-fix-issues-list:hover{
  color: inherit!important;
  background-color: inherit!important;
  border-color: inherit!important;
}

.pt-mobile-45px{
  @media all and (max-width: 991px){
    padding-top: 45px;
  }
}

.extra-text-plan-form{
  margin-bottom: -60px;
  @media screen and (max-width: 991px) {
    margin-bottom: -20px;
  }
}

.technical-break{
  min-height: 100vh;
  background: #FFF7EC;
  @media screen and (max-width: 991px) {
    flex-direction: column;
    .fg-1{
      flex-grow: 1;
    }
  }
  &__dog{
    background: url('/images/psiak_zaslepka.jpg');
    min-height: 100%;
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    @media screen and (max-width: 991px) {
      min-height: 50vh;
      max-height: 50vh;
    }
    @media screen and (max-width: 470px) {
      background-position: bottom;
    }
  }
  .custom-margins{
    margin: 100px 16% 0 12%;
    @media screen and (max-width: 991px) {
      margin: 50px 16% 0 12%
    }
    @media screen and (max-width: 470px) {
      margin: 50px 12% 0 6%
    }
  }
  .background-container{
    position: absolute;
    top: 0;
    left: 0;
    min-height: 100%;
    min-width: 100%;
    z-index: 1;
    max-width: 100%;
    max-height: 100%;
    background: url('/images/technical-break-background.png');
  }
}

.space-between{
  justify-content: space-between;
}

.z-index-10{
  z-index: 10;
}

.opacity-0 {
  opacity: 0;
  -webkit-opacity: 0;
}

.bg-black {
  background-color:#000000;
}