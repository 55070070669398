@import '../../styles/bootstrap-colors.scss';

.waves {
  display: flex;
  min-width: 1620px;
}

.wave {
  fill: $purple;
  stroke: none;
  height: 21px;
  flex: 1;
}

.wavesWrapper {
  width: 100%;
  position: relative;
  margin-top: -20px;
  overflow: hidden;
  height: 20px;
}
