@import './bootstrap-imports.scss';

@font-face {
  font-family: 'Mindset';
  src: url('/fonts/mindset.woff') format('woff'), url('/fonts/mindset.woff2') format('woff2');
}

@font-face {
  font-family: 'MindsetSlim';
  src: url('/fonts/mindset-slim.woff') format('woff'),
    url('/fonts/mindset-slim.woff2') format('woff2');
}

.font-mindset {
  font-family: 'Mindset';
}

.font-weight {
  &-800 {
    font-weight: 800;
  }
  &-700 {
    font-weight: 700;
  }
  &-600 {
    font-weight: 600;
  }
  &-500 {
    font-weight: 500;
  }
  &-400 {
    font-weight: 400;
  }
}

.font-weight-extra-bold {
  font-weight: 800;
}

.text {
  &-white {
    color: white;
  }

  &-sand {
    color: $color-sand;
  }

  &-brown {
    color: $text-03 !important;
  }

  &-green {
    color: #144a3d;
  }

  &-purple {
    color: $text-secondary;
  }

  &-purple2 {
    color: $purple2;
  }

  &-brown-purple {
    color: #652d38;
  }

  &-black {
    color: black;
  }
  &-success-light {
    color: $success-light;
  }

  &-sand {
    color: $color-sand !important;
  }

  &-footer-grey {
    color: $color-footer-grey !important;
  }
}

@for $i from 10 to 80 {
  .font-size-#{$i} {
    font-size: #{$i}px;
  }
}

@for $i from 10 to 80 {
  .line-height-#{$i} {
    line-height: #{$i}px;
  }
}

.font-style {
  &-italic {
    font-style: italic;
  }
}

@include media-breakpoint-down(md) {
  @for $i from 10 to 80 {
    .font-size-mobile-#{$i} {
      font-size: #{$i}px;
    }
  }

  @for $i from 10 to 80 {
    .line-height-mobile-#{$i} {
      line-height: #{$i}px;
    }
  }

  .font-style {
    &-italic {
      font-style: italic;
    }
  }

  .fst-normal {
    font-style: normal;
  }

  .h1-mobile {
    font-size: 50px;
  }

  .h2-mobile {
    font-size: 30px;
  }

  .h3-mobile {
    font-size: 24px;
  }

  .h4-mobile {
    font-size: 20px;
  }

  .h5-mobile {
    font-size: 16px;
  }
}

h2,
.h2 {
  font-size: 40px;

  @include media-breakpoint-down(sm) {
    font-size: 30px;
  }
}
h1,
.h1 {
  font-size: 54px;
}

.link-brown-purple {
  color: #652d38 !important;
}

.link-green {
  color: #144a3d !important;
}
