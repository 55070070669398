@import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';

.sortable {
  cursor: pointer;
}

.admin {
  font-size: 14px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell,
    'Open Sans', 'Helvetica Neue', sans-serif !important;

  .btn {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell,
      'Open Sans', 'Helvetica Neue', sans-serif !important;
    // padding: 0.35rem 0.9rem !important;
  }

  .table {
    font-size: 14px;
  }

  h1,
  .h1 {
    font-size: 2rem;
  }

  h2,
  .h2 {
    font-size: 1.8rem;
  }

  tr td {
    overflow-wrap: break-word;
  }

  input.form-control {
    padding: 8px;
    height: calc(1.5em + 0.5rem + 2px);
  }

  input.form-check {
    padding: 8px;
    height: auto;
  }

  .modal-body {
    max-height: 75vh;
    overflow: visible;
    overflow-y: auto;
  }
}

.admin-modal-body {
  max-height: 75vh;
  overflow: visible;
  overflow-y: auto;
}

.top-sticky {
  position: sticky;
  top: 0;
  background: #fff;
  padding-top: 2.8rem;
  z-index: 800;
  padding-bottom: 0.4rem
}

.pt-admin {
  padding-top: 5rem
}
