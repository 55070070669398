.btn-primary {
  color: #fff;
  @extend .font-weight-700;
}

.btn {
  transition: background-color 0.1s ease-in-out;
}

:not(.btn-group) {
  .btn {
    padding: 12px 25px;
    border-radius: 50rem;
    &.disabled {
      background-color: #e9e5e0;
      border: 1px solid #e9e5e0;
    }
    .btn-primary {
      &:hover {
        background-color: #fa6527;
        border-color: #fa6527;
      }
    }
    &.btn-lg {
    }
  }
}

.btn-success-light {
  @include button-variant($success-light, $success-light);
}

.btn-wide {
  padding: 0.95rem 3rem;
  font-size: 18px;
  line-height: 1.5;
  border-radius: 3.688em;
  min-width: 180px;
}

.btn-lg {
  padding: 0.86rem 2.42rem;
  font-size: 19.6px;
}

.link-button {
  font-weight: 700 !important;
  padding: 0;
  background: transparent;
  border: none;
  cursor: pointer;
  text-align: left;
}
