@import './bootstrap-colors.scss';
$theme: 'piesotto' !default;

$min-contrast-ratio: 1.85 !default;

// Body

$body-color: $gray-800 !default;

// Fonts


// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 1100px,
  xl: 1170px
) !default;

// stylelint-disable value-keyword-case
$font-family-sans-serif: 'Poppins', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif,
  'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji' !default;
$font-family-serif: Georgia, Cambria, 'Times New Roman', Times, serif !default;
// stylelint-enable

$font-size-base: 1rem !default;
$headings-font-weight: 800 !default;

// Tables

$table-border-color: rgba(0, 0, 0, 0.1) !default;

$table-bg-scale: 0 !default;

// Buttons

$input-btn-padding-y: 0.65rem !default;
$input-btn-padding-x: 1.1rem !default;
$btn-font-family: $font-family-sans-serif !default;
$btn-font-size: 1rem !default;
$btn-font-size-sm: 0.688rem !default;

$btn-border-radius: 1.5em !default;
$btn-border-radius-lg: 2.888em !default;
$btn-border-radius-sm: 0.994em !default;

// Forms

$input-border-color: rgba(0, 0, 0, 0.1) !default;
$input-group-addon-bg: $gray-200 !default !default;

// Navbar

$navbar-dark-color: rgba($white, 0.6) !default;
$navbar-dark-hover-color: $white !default;
$navbar-light-hover-color: $body-color !default;
$navbar-light-active-color: $body-color !default;

// Tooltips

$tooltip-font-size: 11px !default;

// Badges

$badge-font-weight: 400 !default;
$badge-padding-y: 0.6em !default;
$badge-padding-x: 1.2em !default;

// Alerts

$alert-border-width: 0 !default;

// Tooltips
$tooltip-bg-color: $text-03;

// $tooltip-font-size: $font-size-sm !important;
$tooltip-padding-y: 0.5rem !default;
$tooltip-padding-x: 1rem !default;
$tooltip-max-width: 600px !default;
$tooltip-border-radius: 10px !default;
$tooltip-color: $white !default;
$tooltip-bg: $tooltip-bg-color !default;

$tooltip-arrow-width: 0.8rem !default;
$tooltip-arrow-height: 0.4rem !default;
$tooltip-arrow-color: $tooltip-bg-color !default;


// $input-border-radius:                   12px !default;
// $input-border-radius-lg:                15px !default;

@import 'bootstrap/scss/bootstrap.scss';
