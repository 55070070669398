@import './bootstrap-colors.scss';
@import './bootstrap-imports.scss';

.nav-underscored {
  .nav-link {
    // border-radius: 5px 5px 5px 5px    ;
    text-align: center;
    color: $dark-brown;
    // border-bottom: solid 4px #ddd;
    transition: border-bottom-color 0.15s, color 0.15s;
    font-weight: 600;
    cursor: pointer;
    padding: 1rem;

    &.active {
      border-bottom-color: $purple2;
      font-weight: 700;
    }
  }

  @include media-breakpoint-down(md) {
    .nav-link {
      padding-left: 0.1rem;
      padding-right: 0.1rem;
    }
  }
}