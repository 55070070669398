@import '../../../styles/colors.scss';

.footerInput {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: right;
  border: none  !important;
  border-top-left-radius: 50rem;
  border-bottom-left-radius: 50rem;
  color: $text-secondary;

  &::placeholder {
    color: #675944;
    font-weight: 500;
    font-size: 14px;
  }
}

.submitButton {
  color: transparent;
  background-color: transparent;
  border: none;
  padding: 0 !important;
}

.social:hover {
  color: $color-primary-hover;
}

.siteLink {
  cursor: pointer;
  &:hover {
    color: $color-primary
  }
}

.legalText {
  font-size: 11px;
  line-height: 12px !important;
}

.noHover:hover {
  text-decoration: none;
  color: $color-primary

}

.flavorSiteLink {

  cursor: pointer;
}

.flavorSiteLink:hover .flavorSiteLink{
  color: 'red';
}