@import '../../styles/bootstrap-imports.scss';

.caption {
  font-size: 36px;
  line-height: 36px;
  margin-left: 10px;
  display: inline-block;
  font-family: 'Mindset';
  font-weight: 500;
  color: white;
  text-align: center;
  margin: auto;
}

.captionWrapper {
  max-width: 90%;
}

@include media-breakpoint-up(lg) {
  .caption {
    font-size: 68px;
    line-height: 72px;
  }
  .captionWrapper {
    max-width: 75%;
    min-height: 200px
  }
}
