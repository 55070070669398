@for $i from 0 to 41 {
  $mar: ($i * 5);
  .ml-#{$mar}-px {
    margin-left: #{$mar}px;
  }
}
@for $i from 0 to 41 {
  $mar: ($i * 5);
  .mr-#{$mar}-px {
    margin-right: #{$mar}px;
  }
}
@for $i from 0 to 41 {
  $mar: ($i * 5);
  .mt-#{$mar}-px {
    margin-top: #{$mar}px;
  }
}
@for $i from 0 to 41 {
  $mar: ($i * 5);
  .mb-#{$mar}-px {
    margin-bottom: #{$mar}px;
  }
}
@for $i from 0 to 41 {
  $mar: ($i * 5);
  .ml-n#{$mar}-px {
    margin-left: -#{$mar}px;
  }
}
@for $i from 0 to 41 {
  $mar: ($i * 5);
  .mr-n#{$mar}-px {
    margin-right: -#{$mar}px;
  }
}
@for $i from 0 to 41 {
  $mar: ($i * 5);
  .mt-n#{$mar}-px {
    margin-top: -#{$mar}px;
  }
}
@for $i from 0 to 41 {
  $mar: ($i * 5);
  .mb-n#{$mar}-px {
    margin-bottom: -#{$mar}px;
  }
}

@include media-breakpoint-down(sm) {
  @for $i from 0 to 70 {
    $mar: ($i * 5);
    .mt-mobile-xs-#{$mar}-px {
      margin-top: #{$mar}px;
    }
  }
}

@include media-breakpoint-down(md) {
  @for $i from 0 to 70 {
    $mar: ($i * 5);
    .mt-mobile-#{$mar}-px {
      margin-top: #{$mar}px;
    }
  }
  @for $i from 0 to 41 {
    $mar: ($i * 5);
    .ml-mobile-#{$mar}-px {
      margin-left: #{$mar}px;
    }
  }
  @for $i from 0 to 41 {
    $mar: ($i * 5);
    .mr-mobile-#{$mar}-px {
      margin-right: #{$mar}px;
    }
  }
  @for $i from 0 to 41 {
    $mar: ($i * 5);
    .mb-mobile-#{$mar}-px {
      margin-bottom: #{$mar}px;
    }
  }
  @for $i from 0 to 41 {
    $mar: ($i * 5);
    .ml-mobile-n#{$mar}-px {
      margin-left: -#{$mar}px;
    }
  }
  @for $i from 0 to 41 {
    $mar: ($i * 5);
    .mr-mobile-n#{$mar}-px {
      margin-right: -#{$mar}px;
    }
  }
  @for $i from 0 to 41 {
    $mar: ($i * 5);
    .mt-mobile-n#{$mar}-px {
      margin-top: -#{$mar}px;
    }
  }
  @for $i from 0 to 41 {
    $mar: ($i * 5);
    .mb-mobile-n#{$mar}-px {
      margin-bottom: -#{$mar}px;
    }
  }
}

@for $i from 0 to 41 {
  $pad: ($i * 5);
  .pl-#{$pad}-px {
    padding-left: #{$pad}px;
  }
}
@for $i from 0 to 41 {
  $pad: ($i * 5);
  .pr-#{$pad}-px {
    padding-right: #{$pad}px;
  }
}
@for $i from 0 to 41 {
  $pad: ($i * 5);
  .pt-#{$pad}-px {
    padding-top: #{$pad}px;
  }
}
@for $i from 0 to 41 {
  $pad: ($i * 5);
  .pb-#{$pad}-px {
    padding-bottom: #{$pad}px;
  }
}

@include media-breakpoint-down(md) {
  @for $i from 0 to 41 {
    $pad: ($i * 5);
    .pl-mobile-#{$pad}-px {
      padding-left: #{$pad}px !important;
    }
  }
  @for $i from 0 to 41 {
    $pad: ($i * 5);
    .pr-mobile-#{$pad}-px {
      padding-right: #{$pad}px !important;
    }
  }
  @for $i from 0 to 41 {
    $pad: ($i * 5);
    .pt-mobile-#{$pad}-px {
      padding-top: #{$pad}px !important;
    }
  }
  @for $i from 0 to 41 {
    $pad: ($i * 5);
    .pb-mobile-#{$pad}-px {
      padding-bottom: #{$pad}px !important;
    }
  }
}

